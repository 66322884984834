@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Montserrat", sans-serif;
  counter-reset: img;
  overflow: hidden !important;
}

.App {
  text-align: center;
  overflow: hidden;
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 25vh;
}

.title {
  width: 25vw;
}
.title p {
  font-size: 1.2rem;
  font-weight: 600;
  color: #3A464F;
  margin: -10px;
  transform: translateY(35px);
}

.countdownParent {
  margin-top: 20px;
}

.countdownParent .countdownTitle {
  color: #3A464F;
  font-size: 2rem;
  font-weight: 500;
  padding-top: 15px;
}

.topRow h2 {
  color: #3A464F;
  font-size: 3rem;
  width: 25vw;
}

.logo {
  transform: scale(2);
}

.countdown {
  display: flex;
  flex-direction: row;
}

.countdown div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: #662B13;
  background-color: #eed394;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 115px;
  height: 115px;
}

.countdown div * {
  margin: 0;
}

.countdown div p {
  font-size: 1.5rem;
}

.background {
  z-index: -1;
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: fit-content;
}

.background img {
    object-fit: cover;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    transform: translateY(5px);
}

.bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

}

.leaderboard {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  width: 25vw;
  background-color: #ffe5adde;
  border-radius: 20px;
  height: 70vh;
}

.profile {
  /* position: relative; */
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  padding-left: 15px;
  margin: auto;
  margin-left: 5px;
  /* width: 80%; */
  /* counter-reset: img; */
}

.profile p:last-child {
  position: absolute;
  right: 0;
}

.scroll {
  position: relative;
  margin: auto;
  transform: scale(1.2);
}

.scroll:before {
  transform: scale(.8);
  counter-increment: img;
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  top: 10%;
  left: 0%;
  content: counter(img);
  color: #FFB23E;
}

.profile p {
  margin: 14px;
}

.events {
  width: 45vw;
  transform: scale(0.92);
  height: 75vh;
}

.noEvents {
  margin: auto;
  color: #3A464F;
  font-size: 1.2rem;
}

.events h2 {
  color: #3A464F;
  font-size: 2rem;
  margin-top: -5px;
}

.eventCard {
  /* background: rgb(176, 228, 239); */
  background-color: #FFF7F0;
  border-radius: 20px;
  padding: 12px;
  /* margin: 10px; */
  width: 95%;
  justify-content: center;
  margin-bottom: 12px;
}

.eventCard p {
  margin: 0;
  padding: 2px;
  color: #072C2E;
}

  /* @media (max-width: 1024px) {
    .eventCard {
      width: 90%;
    }
  } */
  
  .eventCard .headerContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
  }

@media (max-width: 1024px) {
  .eventCard .headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

.eventCard .headerContainer .tags {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.eventCard .headerContainer .eventTitle {
    font-weight: 700;
    font-size: 1.4em;
    /* margin-bottom: 10px; */
    margin: 15px;
    margin-left: 0;
    color: #6D291A;
    text-align: left;
    
}
  
.eventCard .headerContainer .points {
    background-color: #409297;
    color: black;
    border-radius: 20px;
    display: inline-block;
    padding: 3px 10px;
    /* margin-bottom: 10px; */
    font-weight: 600;
}

.eventCard .headerContainer .knights {
  background-color: #83404A;
  color: #FFFACC;
  border-radius: 20px;
  display: inline-block;
  padding: 3px 10px;
  /* margin-bottom: 10px; */
  font-weight: 600;
}
      
.eventCard .headerContainer .sponsor {
    border: 2px solid rgb(233, 186, 166);
    background-color: #DE8E45;
    color: black;
    text-transform: uppercase;
    border-radius: 20px;
    display: inline-block;
    padding: 2px 10px;
    /* margin-bottom: 10px; */
    font-weight: 600;
}

.eventCard .headerContainer .type {
    background-color: #C5673F;
    color: black;
    border-radius: 20px;
    display: inline-block;
    padding: 3px 10px;
    /* margin-bottom: 10px; */
    font-weight: 600;
}

.pin, .clock {
  transform: scale(2);
  margin-left: 8px;
}

  
.eventCard .timeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  color: #B7D6D6;

}

.eventCard .eventDescription {
    font-size: 18px;
    font-weight: 400;
    color: #072C2E;
    padding: 5px;
    text-align: left;
  }
  
  @media only screen and (max-width: 1024px) {
    .eventCard .eventDescription {
      font-size: 1.2em;
    }
  }

.sponsors {
  width: 25vw;
  height: 70vh;
  background-color: #ffffffc8;
  border-radius: 20px;
  margin: 20px;
}

.sponsorLogos {
  height: 70vh;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sponsorLogos div {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 15%; */
  gap: 35px;
  margin: 20px;
  /* padding: 10px; */
}

.sponsorLogos div img {
  transform: scale(1.2);
}

.solana {
  transform: scale(1.5);
  padding-bottom: 5px;
}